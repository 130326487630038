import React from "react";
import styled, {useTheme} from "styled-components";
import CameraIcon from "./icons/CameraIcon";
import useSite from "src/core/sites/hooks/useSite";
import styles from "src/integrations/tymberQRCode/styles";
import PrimaryButton from "src/core/common/components/elements/button/PrimaryButton";
import {CloseIcon} from "src/core/common/components/elements/button/CloseButton";

export default function AllowCameraAccess({onButtonClick, goToMenu, error}) {
  const site = useSite();

  const theme = useTheme();
  const _styles = styles(theme, site);

  return (
    <Container styles={_styles}>
      <CloseButton onClick={goToMenu} />
      <CameraIcon color={_styles.root.backgroundColor} />
      {!error && (
        <>
          <MainText styles={_styles.root}>Please allow camera access</MainText>
          <SecondaryText styles={_styles.root}>
            We need access to your camera so that you can scan products in-store.
          </SecondaryText>
          <SecondaryText styles={_styles.root}>
            On the next screen, tap "Scan" to start shopping! (Make sure you tap "Allow"
            if you're asked for camera access permission)
          </SecondaryText>
          <Button onClick={onButtonClick}>Allow Camera Access</Button>
        </>
      )}

      {error && (
        <>
          <MainText styles={_styles.root}>
            Looks like we were unable to get access to your camera.
          </MainText>
          <Button onClick={goToMenu}>Go to menu</Button>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  * > button {
    background-color: ${({styles}) => styles.root.backgroundColor};
    border-radius: ${({styles}) => styles.button.borderRadius};
    font-weight: ${({styles}) => styles.button.fontWeight};
  }
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
`;

const MainText = styled.span`
  text-align: center;
  font-size: ${({styles}) => styles.titleFontSize};
  font-weight: ${({styles}) => styles.titleFontWeight};
  padding: 40px 20px 24px;
`;

const SecondaryText = styled.span`
  text-align: center;
  font-size: ${({styles}) => styles.subtitleFontSize};
  font-weight: ${({styles}) => styles.subtitleFontWeight};
  padding: 0 20px 30px;
`;

const Button = styled(PrimaryButton)``;
