import React from "react";
import localStorage from "src/core/common/localStorage";
import {detail} from "src/core/api/products";
import Product from "src/core/common/models/product";
import {populateRelations} from "src/core/api/utils";
import useProductPath from "src/core/products/hooks/useProductPath";
import QRCodeFramedImage from "./assets/qr-code-framed.svg";
import IPhoneImage from "./assets/iphone.png";

export const QR_CODE_ONBOARDING_VIEW_MAX_INDEX = 2;

export const QR_CODE_ONBOARDING_CONFIG = [
  {
    main: "Point the camera to scan the QR code",
    secondary: "Frame the QR Code in center",
    image: QRCodeFramedImage,
    button: "Next",
  },
  {
    main: "Once QR code is scanned the product page will open automatically.",
    secondary: "",
    image: () => <img alt="phone" style={{height: "200px"}} src={IPhoneImage} />,
    button: "Get started",
  },
];

export default function useQRCodeOnboarding() {
  const [, , getProductPathDefinition] = useProductPath();

  const hasQRCodeOnboardingComplete = localStorage.getItem(
    HAS_QR_CODE_ONBOARDING_COMPLETE
  );

  function setHasQRCodeOnboardingComplete(hasQRCodeOnboardingComplete) {
    localStorage.setItem(HAS_QR_CODE_ONBOARDING_COMPLETE, hasQRCodeOnboardingComplete);
  }

  function getProductDetail(url) {
    return detail(url);
  }

  function getProductDetailUrl(productDetail) {
    const product = new Product(
      populateRelations(productDetail.data, productDetail.included)
    );

    return getProductPathDefinition(product);
  }

  return [
    hasQRCodeOnboardingComplete,
    setHasQRCodeOnboardingComplete,
    getProductDetail,
    getProductDetailUrl,
  ];
}

const HAS_QR_CODE_ONBOARDING_COMPLETE = "tymber-user-has-qr-code-onboarding-complete";
